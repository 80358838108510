body,
.ant-table,
.ant-card,
.ant-select,
.ant-dropdown,
.ant-select-dropdown,
.ant-form-item {
  font-family: var(--base-font);
  -webkit-font-smoothing: antialiased;
}
* {
  font-family: "Muli", sans-serif;
}
body {
  /* background-color: var(--app-background-primary); */

  background-color: white;
}

table th,
.ant-btn,
.header-text,
.ant-menu {
  font-family: var(--base-font);
  font-weight: 400;
}
a {
  color: var(--text-color-primary);
}
a:hover {
  color: var(--text-color-primary);
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center;
}
.disabled {
  color: rgba(170, 170, 170, 0.3);
}
#root {
  height: 100%;
}
.ant-form-item-required::before {
  color: var(--text-color-red);
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 10px 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  display: none;
}
.ant-layout {
  /* background: var(--app-background-primary); */
  background: white;
}
a[disabled] {
  opacity: 0.4;
}
@media (max-width: 1200px) {
  .trigger {
    display: inline;
  }
  /* .main-left-nav-wrapper{
    display: none;
  } */
}
/* layout style start */
.ant-layout.main-wrapper {
  justify-content: space-between;
}
.main-left-nav-wrapper {
  width: 200px;
}
.main-right-content-wrapper {
  padding: 30px;
  background-color: white;
  box-shadow: 0 5px 25px 0 rgba(189, 194, 220, 0.4);
  border: solid 1px #d1d6f3;
  min-height: 100vh;
  margin-top: 48px;
}
.main-right-content-wrapper.no-left-nav {
  margin-left: auto;
  margin-right: auto;
}
.ant-layout-sider {
  position: fixed !important;
  /* left: calc(50vw - 1330px / 2); */
  width: 100%;
  top: 120px;
  z-index: 10;
}
@media (min-width: 1441px) {
  .ant-layout.main-wrapper {
    max-width: 80%;
    width: 80%;
    margin: 0 auto;
  }
  .main-right-content-wrapper {
    width: calc(100% - 220px);
  }
  .top-nav-container {
    width: 95%;
    margin: 0 auto;
  }
  .ant-layout-header {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 1440px) {
  .ant-layout.main-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  .main-right-content-wrapper {
    width: 960px;
  }
  .top-nav-container {
    max-width: 100%;
    margin: 0 auto;
    padding-left: 85px;
    padding-right: 85px;
  }
}
@media (max-width: 1220px) {
  .ant-layout.main-wrapper {
    max-width: 1000px;
    margin: 0 auto;
  }
  .main-right-content-wrapper {
    width: calc(100% - 50px);
  }
  .main-left-nav-wrapper {
    width: 50px;
  }
}
@media (max-width: 1040px) {
  .ant-layout.main-wrapper {
    max-width: 90%;
  }
}
@media (max-width: 768px) {
  .top-nav-container {
    padding-left: 0;
    padding-right: 0;
  }
}
