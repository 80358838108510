/***** custom fonts start*****/
@import url("https://fonts.googleapis.com/css?family=Muli:400,400i,600,700&display=swap");

/* @font-face {
  font-family: "Abadi";
  src: url("../../fonts/abadi-regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Abadi";
  src: url("../../fonts/abadi-bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Abadi";
  src: url("../../fonts/abadi-light.ttf") format("truetype");
  font-weight: 300;
} */

/***** custom fonts end*****/
.font-weight-bold {
  font-weight: 700;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.font-color-primary {
  color: var(--text-color-dark);
}
.font-color-green {
  color: var(--text-color-green);
}
.font-color-white {
  color: white;
}
.vertically-middle {
  vertical-align: middle;
}
.font-color-blue {
  color: var(--text-color-primary);
}