.ant-modal-header {
  padding: 12px 15px;
}
.ant-modal-title {
  font-weight: 500;
  font-size: var(--base-font-size);
  font-family: var(--base-font);
}
.ant-modal-body,.ant-modal-footer {
  
  padding: 10px 15px;
}
.ant-modal-close-x {
  width: 50px;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
}
.ant-modal-body p{
  font-size: var(--base-font-size);
  font-family: var(--base-font);
}
.ant-modal-body{
  min-height: 75px;
}
/* style changes for text type modal with text and button  */
.ant-modal.text-type-modal {
  max-width: 500px;
}
.ant-modal.text-type-modal.small {
  max-width: 400px;
}
.text-modal-multiple-btns {
  display: flex;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.text-modal-multiple-btns .ant-btn.secondary-btn {
  width: 49%;
  margin-left: 12px;
}
.text-modal-multiple-btns .ant-btn.ant-btn-primary {
  width: 49%;
  min-width: auto;
  font-weight: 600;
}
.text-type-modal .heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: var(--text-color-dark);
}
.text-type-modal .description {
  font-size: 14px;
  color: var(--text-color-dark);
}
.text-type-modal .description.small-grey-text {
  font-size: 14px;
  line-height: 18px;
  color: #353949;
}
.text-black {
  color: #000000;
}
.text-type-modal .ant-modal-content {
  padding-top: var(--text-type-modal-v-padding);
  padding-bottom: var(--text-type-modal-v-padding);
}

.light-gray-clr {
  color: #999ebc;
  font-size: 14px;
  line-height: 18px;
}
.modal-flex-wrapper {
  display: flex;
  width: 1000px;
}
.text-type-modal-image {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}
.desc-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.text-type-modal-image img {
  width: 100%;
}
.text-type-modal .ant-modal-close-x i {
  color: var(--text-color-dark);
}
.website-modal-wrapper {
  position: relative;
  background-color: white;
}
.website-modal-wrapper .ant-form-item {
  margin-bottom: 10px;
}
.website-modal-wrapper .ant-form-item-label {
  padding-bottom: 0 !important;
}
.text-type-modal .ant-modal-body{
  padding: 20px 0;
}
@media(max-width: 500px){
  .website-modal-wrapper{
    max-width: 100%;
  }
  .ant-modal.text-type-modal{
    width: 300px !important;
  }
  .text-type-modal .heading{
    font-size: 16px;
  }
  .account-types-list li{
    width: 80px;
    height: 80px;
  }
}
