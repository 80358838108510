.ant-btn {
  border-radius: var(--controls-border-radius);
}
.ant-btn:not(.queue-status-btn):not(.queue-inner-btn):not(.ant-btn-sm) {
  box-shadow: var(--btn-box-shadow);
  border: none;
  font-size: var(--label-font-size) !important;
  -webkit-font-smoothing: auto;
  line-height: 1.6;
  height: 35px !important;
}
.ant-btn.ant-btn-link {
  box-shadow: none !important;
  color: var(--text-color-primary);
  font-size: 14px !important;
}
.ant-btn-link.link-black-with-icon {
  font-weight: 700;
  color: var(--text-color-dark);
  padding-left: 0;
  padding-right: 0;
}
.ant-btn-link.link-black-with-icon img {
  margin-left: 5px;
}
.ant-btn-sm {
  line-height: 32px;
  height: 32px;
  -webkit-font-smoothing: auto;
  padding-top: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.ant-btn-primary {
  background-color: var(--background-color-primary);
  border-color: var(--background-color-primary);
  /* background-image: linear-gradient(96deg, #11c1ff, #078dff); */
  transition: all 0.5s ease;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: var(--box-shadow-btn);
}
.ant-btn-primary:not(.ant-dropdown-trigger):hover,
.ant-btn-primary:not(.ant-dropdown-trigger):focus {
  border-color: var(--background-color-primary);
  color: white;
  background-color: var(--background-hover-color-primary);
  box-shadow: none;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--background-hover-color-primary);
  border-color: var(--background-hover-color-primary);
  color: white;
}
.ant-btn-primary[disabled] {
  color: white;
  background-color: var(--light-grey-color);
  background-image: none;
}
.ant-btn-background-ghost.ant-btn-primary {
  /* color: var(--background-color-primary); */
  color: var(--text-color-dark);
  border-color: var(--background-color-primary);
  background-color: #ffffff !important;
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: var(--background-color-primary);
  border-color: var(--background-color-primary);
}
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  background-color: var(--light-grey-color);
  background-image: none;
  color: #fff;
  border-color: #d1d6f3;
}
.ant-btn-circle {
  width: 32px;
  font-size: 13px;
  height: 32px;
  position: relative;
}
.ant-btn .anticon {
  font-size: var(--base-font-size);
}
.ant-btn-circle .anticon-plus,
.ant-btn-circle .anticon-reload {
  position: relative;
}
.ant-btn[disabled] {
  box-shadow: none;
}

.react-datepicker-wrapper .ant-btn {
  vertical-align: middle;
}
.react-datepicker-wrapper .ant-btn[readonly] {
  box-shadow: var(--btn-box-shadow) !important;
  background-color: #f5f5f5;
}
.react-datepicker-wrapper .ant-btn .anticon {
  left: 7.5px;
}

.dob-input-with-btn .dob-calendar-popper {
  transform: translateX(0) !important;
  top: 26px !important;
}
.dob-calendar-popper.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle {
  border-bottom: 1px solid #f0f0f0;
  top: 0;
  bottom: auto;
  border-top: none;
  margin-bottom: 0;
  margin-top: -8px;
}
.dob-calendar-popper.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle:before {
  top: -1px;
  border-bottom-color: #aeaeae;
  bottom: auto;
  border-top-color: transparent;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background-color: var(--background-color-primary);
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: var(--background-color-primary);
}
.ant-btn-icon-only.ant-btn-sm {
  height: 32px;
  width: 32px;
  line-height: 32px;
}
.ant-btn.ant-btn-icon-only.ant-btn-sm > .anticon {
  line-height: 0;
}
.es-btn.ant-btn-circle i {
  margin-top: 0 !important;
}
.ant-btn-default {
  box-shadow: var(--default-btn-shadow);
  border: solid 1px var(--light-grey-color);
}
.ant-btn-default:hover,
.ant-btn-default:focus {
  background-color: var(--background-color-primary);
  color: #fff;
  box-shadow: none;
}
.ant-btn-icon-only .anticon {
  font-size: 18px;
  position: absolute;
  left: 12px;
  top: 12px;
}
.ant-switch {
  background-color: var(--light-grey-color);
}
.ant-switch-checked {
  background-color: var(--purple-color);
}
.ant-btn-clicked:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}
[ant-click-animating-without-extra-node]:after {
  animation: 0s !important;
  --antd-wave-shadow-color: transparent !important;
  box-shadow: none;
  opacity: 1;
}
/* Avatar style  */
.ant-avatar {
  background-color: var(--background-color-primary);
  /* background-image: linear-gradient(96deg, #11c1ff, #078dff); */
}
.ant-btn-primary.ant-btn-circle {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
button.ant-btn.form-primary-btn.ant-btn-primary {
  /* padding: 16px 34px; */
  font-weight: bold;
  font-size: 16px !important;
  line-height: 30px !important;
  color: #ffffff !important;
  height: 40px !important;
  background-color: var(--background-color-primary);
  border-radius: var(--controls-border-radius);
  width: 100%;
  transition: all 0.3s 0s;
}
button.ant-btn.form-primary-btn.ant-btn-primary:hover,
button.ant-btn.form-primary-btn.ant-btn-primary:focus {
  background-color: var(--background-hover-color-primary);
}
button.ant-btn.auth-form-secondary-btn.ant-btn-primary {
  /* padding: 16px 34px; */
  font-weight: bold;
  font-size: 16px !important;
  line-height: 30px !important;
  background: #f5f6ff !important;
  height: 40px !important;
  color: var(--text-color-primary);
  border-radius: 6px;
  width: 100%;
}
@media (max-width: 992px) {
  .ant-btn {
    font-size: var(--label-font-size) !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.ant-btn.queue-inner-btn {
  height: 32px;
  width: 32px;
  line-height: 32px;
}
.ant-btn.queue-inner-btn .anticon {
  left: 6px;
  top: 6px;
}
.ant-btn.purple-btn,
.ant-btn-background-ghost.purple-btn,
.ant-btn-background-ghost.ant-btn-primary.purple-btn {
  background-color: var(--purple-color) !important;

  color: white;
}
.ant-btn.purple-btn:hover,
.ant-btn-background-ghost.purple-btn:hover,
.ant-btn-background-ghost.ant-btn-primary.purple-btn:hover {
  background-color: var(--purple-color) !important;
  background-image: none !important;

  color: white;
}
.ant-btn.ant-btn-sm {
  height: 35px !important;
  border: 1px solid var(--light-grey-color);
  box-shadow: 0 2px 8px 0 var(--cloudy-blue-28);
  line-height: 35px;
  padding-top: 0;
  color: var(--text-color-dark);
}
.ant-btn.ant-btn-sm:hover,
.ant-btn.ant-btn-sm:focus {
  background-color: transparent;
  color: var(--text-color-dark);
}
