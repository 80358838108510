.ant-collapse-content{
  overflow: visible;
}
.ant-collapse > .ant-collapse-item:last-child{
  border-radius: 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  text-transform: capitalize;
  font-size: var(--base-font-size)
}
.ant-collapse{
  font-family: var(--base-font);
}