.ant-alert-success {
  border: none;
  background-color: var(--background-color-primary);
  color: var(--color-white);
  font-family: var(--base-font);
}
.ant-alert-success .ant-alert-icon {
  color: var(--background-color-primary);
}
.ant-alert-close-icon .anticon-close {
  color: var(--color-white);
}
.ant-alert-message,
.ant-notification {
  font-size: var(--base-font-size);
  font-family: var(--base-font);
  font-weight: 600;
  padding-left: 20px;
  /* background: url(../../images/error-icon.svg); */
  background-repeat: no-repeat;
}
.ant-notification-notice-close {
  color: black;
}
.ant-notification-notice-with-icon {
  position: relative;
}
.ant-notification-notice-icon i:after {
  content: "";
  background-image: url("../../images/icon-error.svg");
  position: absolute;
  left: 8px;
  top: 2px;
  background-size: 17px 17px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
}
.ant-alert-error .ant-alert-icon {
  top: 12px;
}
.ant-alert-error {
  background-color: white;
  border: 1px solid var(--text-color-red);
  border-radius: 6px;
}
.ant-alert-message{
  padding-left: 0;
}