.ant-radio-checked .ant-radio-inner {
  border-color: var(--background-color-primary);
}
.ant-radio-inner:after {
  background-color: var(--background-color-primary);
}
.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-focused .ant-radio-inner {
  border-color: var(--background-color-primary);
}
.ant-radio-button-wrapper-checked{
  background: #fff;
  border-color: var(--background-color-primary);
  color: var(--background-color-primary);
  -webkit-box-shadow: -1px 0 0 0 var(--background-color-primary);
  box-shadow: -1px 0 0 0 var(--background-color-primary);
}
.ant-radio-button-wrapper:hover, .ant-radio-button-wrapper-focused{
  color:var(--background-color-primary)
}
.ant-radio-button-wrapper-checked:hover{
  border-color: var(--background-color-primary);
  -webkit-box-shadow: -1px 0 0 0 var(--background-color-primary);
  box-shadow: -1px 0 0 0 var(--background-color-primary);
}
.ant-radio-button-wrapper-checked:first-child{
  border-color: var(--background-color-primary);
}
.ant-radio-group.ant-radio-group-small{
  font-size: var(--base-font-size);
}
.ant-form input[type="radio"]:focus + .ant-radio-inner{
  border:1px solid var(--background-color-primary)
}