/* top nav css start*/
.kc-topnav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 90px 22px 95px;
  box-shadow: -22px 5px 25px rgba(189, 194, 220, 0.4);
}
.kc-topnav-full {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kc-options-text {
  font-size: 14px;
  color: var(--text-color-dark);
}
.kc-options-text a {
  color: var(--text-color-primary);
  font-size: 14px;
  font-weight: 700;
}
/* top nav css end */
.signup-right-section {
  background-color: white;
  z-index: 10;
}
@media (max-width: 991px) {
  .signup-right-section {
    width: 45%;
  }
}

.signup-wrapper {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: calc(100vh - 200px);
}
.signup-wrapper::before {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  top: -50px;
  left: -10px;
  z-index: 3;
  background-image: url("../images/bg-left-top.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.2;
}
.signup-wrapper::after {
  content: "";
  position: absolute;
  width: 400px;
  height: 400px;
  bottom: -100px;
  right: 0px;
  transform: rotate(180deg);
  background-image: url("../images/bg-left-top.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.2;
}

@media (max-width: 768px) {
  .signup-wrapper::before {
    display: none;
  }
  .signup-wrapper::after {
    display: none;
  }
}

.auth-left-wrap {
  width: 60%;
  /* padding: 30px 90px 40px 95px; */
  display: none;
}
.auth-right-wrap {
  width: 40%;
  height: auto;
  position: relative;
  z-index: 1;
  /* border-radius: 44px 0px 0px 44px; */
  background-size: cover;
  background-position: center;
}
.auth-right-wrap:before {
  content: "";
  background: rgba(53, 57, 73, 0.4);
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 44px 0px 0px 44px; */
}
#ReactBackgroundSlider > figure {
  border-radius: 44px 0px 0px 44px;
}
.auth-main-wrapper {
  width: 100%;
  min-height: 100vh;
  display: none;
  background: #ffffff;
}
.verification-form-main-wrapper {
  width: 367px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 15px 35px 0 var(--sail-color-shadow-ambient),
    0 5px 15px 0 var(--sail-color-shadow-key);
  padding: 34px 24px 44px 24px;
  border-radius: var(--controls-border-radius);
}
.verification-form-wrapper {
  width: 367px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 15px 35px 0 var(--sail-color-shadow-ambient),
    0 5px 15px 0 var(--sail-color-shadow-key);
  padding: 24px 24px 24px 24px;
  border-radius: var(--controls-border-radius);
}
.auth-form-main-wrapper {
  width: 367px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 15px 35px 0 var(--sail-color-shadow-ambient),
    0 5px 15px 0 var(--sail-color-shadow-key);
  padding: 34px 24px 34px 24px !important;
  border-radius: var(--controls-border-radius);
  background-color: #ffffff;
}
.auth-main-wrapper .ant-input:not(.ant-select-search__field) {
  background: #f7f7fa !important;
  border: 1px solid #f7f7fa;
  border-radius: 6px;
  height: 35px;
  padding: 0 10px;
}
.input-with-border-bottom.ant-input:hover,
.input-with-border-bottom.ant-input:focus {
  border-color: var(--text-color-primary) !important;
}
.input-with-border.ant-input:hover,
.input-with-border.ant-input:focus,
.input-with-border .ant-input:hover,
.input-with-border .ant-input:focus {
  border-color: var(--text-color-primary) !important;
}
.auth-form-input-block-container input#password:focus,
.auth-form-input-block-container input#password:hover {
  border-color: var(--text-color-primary);
}
.auth-form-top-heading {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #353949;
  text-align: left;
  font-weight: 800;
  padding-bottom: 5px;
}
.auth-form-subtext {
  font-size: 14px;
  color: var(--text-color-dark);
  margin-top: 5px;
}
.auth-form-input-block-container {
  /* padding-left: 80px; */
  position: relative;
  width: 100%;
}
.auth-form-input-block-container::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
  background-size: 100%;
}
/* .auth-form-input-block-container.mobile-icon {
  background-image: url("../images/mobile-icon.png");
  background-repeat: no-repeat;
}
.auth-form-input-block-container.user-icon {
  background-image: url("../images/user-icon.png");
  background-repeat: no-repeat;
}
.auth-form-input-block-container.email-icon {
  background-image: url("../images/email-icon.png");
  background-repeat: no-repeat;
}
.auth-form-input-block-container.password-icon {
  background-image: url("../images/password-icon.png");
  background-repeat: no-repeat;
} */
.back-icon {
  background-image: url("../images/doc-page-back-btn.png");
  width: 44px;
  height: 47px;
  background-size: 100%;
  display: inline-block;
  background-repeat: no-repeat;
}
.top-back-btn {
  display: inline-block;
}
.logo-img {
  max-width: 104px;
}

/*media query */
@media (max-width: 1024px) {
  .auth-right-wrap {
    display: none;
    transition: all 0.9s ease-in;
  }
  .auth-left-wrap {
    width: 50%;
    /* padding: 30px 60px 40px 60px; */
  }
}
@media (max-width: 992px) {
  .signup-wrapper {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .signup-wrapper {
    padding: 80px 20px 20px;
    align-items: flex-start;
  }
  .kc-topnav {
    padding: 20px 30px;
  }
  .logo-img {
    max-width: 85px;
  }
  .signup-wrapper .ant-row-flex {
    flex-direction: column;
  }
  .signup-right-section {
    width: 100%;
    z-index: 0;
  }
}
@media (max-width: 700px) {
  .auth-form-top-heading {
    font-size: 18px;
    line-height: 1.2;
  }
  .auth-form-subtext {
    font-size: 14px;
    /* line-height: 16px; */
    margin-top: 0;
    margin-bottom: -10px;
  }
  .auth-form-input-block-container::before {
    width: 40px;
    height: 40px;
  }
  .kc-topnav {
    padding: 20px;
  }
}
@media (max-width: 600px) {
  .auth-form-main-wrapper {
    padding: 15px;
  }
}
@media (max-width: 500px) {
  .signup-wrapper {
    width: 100%;
  }
  .auth-form-top-heading {
    font-size: 16px;
    line-height: 1.2;
  }
  .auth-form-main-wrapper{
    width: 100%;
  }
}
@media (max-width: 400px) {
  .kc-options-text {
    margin-top: 10px;
  }
}

/* verification Modal css  */
.ant-modal.mobile-verification-modal {
  width: 604px !important;
  background: #ffffff;
  border-radius: 16px;
  top: 150px;
  padding-bottom: 0;
}
.ant-modal.mobile-verification-modal .ant-input:not(.ant-select-search__field) {
  height: 76px;
  width: 78px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(85, 86, 138, 0.06);
  border-radius: 10px;
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
  color: #353949;
  text-align: center;
}
.mobile-verification-modal .ant-modal-body {
  padding: 50px 40px 35px 40px;
}
.verfication-modal-heading {
  font-size: 24px;
  line-height: 32px;
  color: #353949;
  font-weight: bold;
}
.verfication-modal-smalltext {
  font-size: 14px;
  line-height: 24px;
  color: #999ebc;
}
.model-verify-number {
  font-size: 14px;
  line-height: 24px;
  color: #172b4d;
  margin-top: 6px;
  font-weight: bold;
}
.enter-modal-code {
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #353949;
  font-weight: 600;
}
input.ant-input.verify-otp-input {
  height: 50px !important;
  border: 0;
  border-bottom: 1px solid rgba(163, 163, 164, 0.16);
  font-weight: bold;
  font-size: 36px;
  line-height: 32px;
  width: 100% !important;
  box-shadow: none !important;
}
.simple-btn {
  color: var(--text-color-dark);
}
.auth-left-wrap.show {
  display: block;
}
.auth-main-wrapper.show {
  display: flex;
}
.otp_input_box {
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .otp_input_box {
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .otp_input_box {
    justify-content: space-around;
    align-items: center;
  }
}
.otp_input_box input {
  width: 55px !important;
  height: 35px;
  border-radius: var(--controls-border-radius);
  inset: none;
  background: white;
  border: 1px solid var(--light-grey-color);
  box-shadow: none !important;
  outline: none !important;
  font-size: 14px;
}
.otp_input_box input:focus {
  border: 1px solid var(--text-color-primary);
}
.field_sep {
  width: 10px;
}
@media (max-width: 500px) {
  .otp_input_box input{
    width: 50px !important;
  }
  .field_sep {
    width: 5px;
  }
  .signin_text {
    display: none;
  }
}
@media (max-width: 768px) {
  .left-section {
    display: none;
  }
}
.verification-container {
  z-index: 1;
}

/* signup form style */
.heading {
  font-size: 20px;
  font-weight: 700;
  color: #353949;
  line-height: 25px;
}
@media (max-width: 768px) {
  .heading {
    font-size: 18px;
  }
}
.right-section-block {
  width: calc(100% - 367px);
  display: flex;
  flex-wrap: wrap;
  margin-left: 70px;
  margin-bottom: -100px;
  flex-direction: column;
  margin-top: 35px;
}
@media (max-width: 991px) {
  .right-section-block {
    width: 100%;
    margin-left: 0px;
    margin-top: 30px;
  }
}

.left-intent {
  display: flex;
  justify-items: space-between;
}
@media (max-width: 991px) {
  .left-intent {
    display: block;
    justify-content: start;
    align-items: center;
  }
  .main-card {
    width: 100%;
  }
}
.right-intent .img-card img {
  margin-top: 40px;
  width: 100%;
}
@media (max-width: 768px) {
  .left-intent {
    width: 100%;
  }
}
.right-intent .main-card {
  display: flex;
  width: 33%;
  flex-wrap: wrap;
}

.card-1,
.card-2,
.card-3 {
  margin: 0px 20px 0 0;
  padding: 0 10px;
  width: 100%;
}

.card-heading {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 12px;
}
.card-text {
  font-size: 12px;
}
@media (max-width: 991px) {
  .right-intent .main-card {
    width: 100%;
  }
  .card-1,
  .card-2,
  .card-3 {
    margin-top: 0px;
    padding: 10px;
  }
}
@media (max-width: 991px) {
  .right-intent .img-card img {
    width: 48vw;
    padding: 10px;
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  .right-intent .img-card img {
    width: 94vw;
    padding: 0;
  }
}
