:root {
  --text-color-dark: #353949;
  --text-color-red: #f63774;
  --text-color-green: #1fc9c1;
  --text-color-yellow: #fecd2f;
  --bright-sky-blue: #11c1ff;
  --color-white: #ffffff;
  --icons-color: #656565;
  --base-font-size: 14px;
  --dark-grey: #707070;
  --dark-grey-2: #4d4d4d;
  --light-background-grey: rgba(230, 236, 243, 0.47);
  --text-color-primary: #078dff;
  --background-color-primary: #078dff;
  --background-hover-color-primary: #07c5ff;
  --purple-color: #7007ff;
  --purple-color-hover: #a911ff;
  --primary-gradient-bg: linear-gradient(101deg, #11c1ff, #078dff);
  --light-primary-color: rgb(219, 245, 232);
  --light-primary-hover-color: rgba(219, 245, 232, 0.5);

  --btn-box-shadow: 0 0 0 1px rgba(215, 215, 241, 0.03),
    0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  /* --btn-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  /* --app-background-primary: #e8efef; */
  /* --app-background-primary: #f7f7f7; */
  --app-background-primary: #f8faff;
  --title-font-size: 16px;
  --box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  --base-font: "Muli", sans-serif;
  --select-box-shadow: 0 2px 8px rgba(189, 194, 220, 0.4);
  --light-grey-color: #d1d6f3;
  --cloudy-blue-28: rgba(189, 194, 220, 0.28);
  --box-shadow-btn: 0 5px 4px 0 rgba(209, 214, 243, 0.4);
  --default-btn-shadow: 0 5px 25px 0 rgba(189, 194, 220, 0.4);
  --purple-gradient-bg: linear-gradient(135deg, #a911ff, #7007ff);
  --bluey-grey: #999ebc;
  --label-font-size: 14px;

  --small-font: 12px;
  --block-box-shadow: 0 2px 8px 0 rgba(189, 194, 220, 0.28);
  --light-purple: #545df9;
  --sail-color-shadow-key: rgba(0, 0, 0, 0.12);
  --sail-opacity-8: 0.08;
  --sail-opacity-shadow-ambient: var(--sail-opacity-8);
  --sail-color-gray-700-rgb: 60, 66, 87;
  --sail-color-shadow-ambient: rgba(
    var(--sail-color-gray-700-rgb),
    var(--sail-opacity-shadow-ambient)
  );
  --sail-shadow-large: 0 15px 35px 0 var(--sail-color-shadow-ambient),
    0 5px 15px 0;
  --controls-border-radius: 3px;
  --text-type-modal-v-padding: 30px;
  --light-blue-color: #f5faff;
}

@media (min-width: 1681px) {
  :root {
    --base-font-size: 15px;
  }
}
