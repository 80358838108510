
.ant-spin-spinning{
  opacity: 1;
  position: fixed;
  display: inline-block;
  left: 0;
  right: 0;
  top: 0%;
}
.ant-select-dropdown-menu  .ant-spin-spinning{
  position: relative;
}