.ant-calendar-range .ant-calendar-in-range-cell:before{
  background: var(--light-primary-color);
}
.ant-calendar-date:hover{
  background: var(--light-primary-color);
  color: #fff ;
}
.ant-calendar-today .ant-calendar-date{
  border-color: transparent;
  color : var(--text-color-green);
}
.ant-calendar-selected-date .ant-calendar-date, .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-selected-end-date .ant-calendar-date{
  background: var(--text-color-green);
  color: #fff !important;
}
.ant-calendar-selected-date .ant-calendar-date:hover, .ant-calendar-selected-start-date .ant-calendar-date:hover, .ant-calendar-selected-end-date .ant-calendar-date:hover{
  background: var(--text-color-green);
  color: #fff;
}