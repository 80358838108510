.ant-select-auto-complete {
  width: 100% !important;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  font-size: var(--title-font-size);
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  line-height: 38px;
}
.ant-select-auto-complete.ant-select .ant-input{
  height: 32px;
  position: relative;
  top: -1px;
  box-shadow: none;
  font-weight: 14px;
  color: var(--text-color-dark);
  /* font-weight: 700; */
}
.ant-select-auto-complete.ant-select:not(.filter_menu) .ant-select-selection{
  /* -webkit-box-shadow: var(--btn-box-shadow); */
  /* box-shadow: var(--btn-box-shadow); */
  border: 1px solid var(--light-grey-color);
  outline: none;
}
 .has-error .ant-select-auto-complete.ant-select:not(.filter_menu) .ant-select-selection{
  border: 1px solid var(--text-color-red);

 }
.ant-select-auto-complete.ant-select .ant-select-selection:focus{
  box-shadow: none;
}
.ant-select-auto-complete{
  /* border: 1px solid var(--light-grey-color); */
  border-radius: 6px;
  height: 40px;
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder{
  margin-left: 0;
  margin-right: 0;
}
.ant-select-auto-complete.ant-select .ant-input:focus, .ant-select-auto-complete.ant-select .ant-input:hover{
  border-color: transparent;
}
/* .has-error .ant-select-auto-complete{
  border: 1px solid var(--text-color-red);
} */
/* .ant-form-item-control:not(.has-error).ant-form-item-control .ant-select-focused{
  border: 1px solid var(--purple-color);
} */
.has-error .ant-select-open .ant-select-selection, .has-error .ant-select-focused .ant-select-selection{
  border-color: transparent;
  box-shadow: none;
}
.ant-select-selection--single{
  height: 35px;
}
.ant-select-auto-complete.ant-select-focused.ant-select-open .ant-select-selection{
 border-color: var(--purple-color);
}