
.ant-layout-sider-collapsed + .ant-layout .content {
  padding-left: 104px;
}
.ant-layout-sider {
  position: fixed !important;
  left: calc(50vw - 1330px / 2);
  width: 200px !important;
  top: 60px;
  z-index: 10;
}
.ant-layout-sider .ant-menu {
  background: transparent;
}
@media (max-width: 1410px) {
  .ant-layout-sider {
    left: 20px;
  }
  .content.ant-layout-content {
    padding-left: 238px;
  }
}
.ant-layout-sider {
  background: transparent !important;
  position: relative;
  height: auto;
  min-height: 100vh;
}
.ant-layout-sider-collapsed .logo-small {
  display: inline;
}
.ant-layout-sider-collapsed .logo-big {
  display: none;
}
.ant-layout-sider .ant-menu {
  background: transparent;
}
.ant-layout-sider .ant-menu .ant-menu-item {
  /* border-bottom: 1px solid #ebeef2; */
  margin-bottom: 0;
  height: 30px;
  line-height: 30px;
  outline: none;
  font-size: var(--base-font-size);
}
.ant-layout-sider-collapsed + .ant-layout .content {
  padding-left: 104px;
}
.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
@media(max-width:1200px){
  .ant-layout-sider{
    width: 30px !important;
    min-width: 30px !important;
    padding-top: 40px;
  }
  .ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item{
    height: 20px;
    line-height: 20px;
  }
  .ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item a{
    color:rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-weight: 700;
  }
   .ant-menu-submenu.ant-menu-submenu-popup > .ant-menu{
    background-color: rgba(0, 0, 0, 0.75);
    padding: 5px 0 3px;
    position: relative;
  }
  .ant-menu-submenu.ant-menu-submenu-popup > .ant-menu:before{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6.5px 10px 6.5px 0;
    border-color: transparent rgba(0, 0, 0, 0.75) transparent transparent;
    position: absolute;
    left: -9px;
    top: 14px;
    display: block;
    content: "";
  }
  

  .ant-menu-submenu-popup{
    background: transparent;
  }
}