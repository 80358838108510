.main-left-nav-wrapper
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background: var(--background-color-primary);
  border-radius: 10px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-sub .ant-menu-item-selected {
  background: transparent;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-sub .ant-menu-item-selected a {
  color: var(--text-color-primary);
}
/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .left-nav-icon{
  filter: contrast(3.5);
} */
.ant-menu-item-selected,
.ant-menu-item:hover {
  color: var(--text-color-primary);
}
.ant-menu-item:active {
  background: transparent;
}
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right: none;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}
.ant-menu-submenu-active {
  color: var(--text-color-primary);
}
.ant-menu-submenu > .ant-menu-submenu-title:hover {
  color: var(--text-color-primary);
}
.ant-menu-submenu
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:before,
.ant-menu-submenu
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow:after {
  /* background-image: linear-gradient(to right,var(--text-color-primary), var(--text-color-primary)); */
  background-color: var(--background-color-primary);
}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 39px;
  height: 40px;
  margin-bottom: 0;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  padding-left: 65px !important;
}
.ant-menu-submenu-arrow {
  display: none;
}
/* .ant-menu-item-selected > a, .ant-menu-item-selected > a:hover, .ant-menu-item > a:hover{
  color: var(--text-color-primary);
} */
.ant-menu-item > a:hover {
  color: var(--text-color-primary);
}
.ant-layout-sider .ant-menu-item-selected > a {
  color: white;
}
.ant-menu-submenu-selected {
  color: var(--text-color-primary);
}
.ant-layout-sider .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: white;
  background: var(--background-color-primary);
  border-radius: 10px;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: transparent;
}
