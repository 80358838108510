.verification_wrapper {
  width: 40%;
  margin: 100px auto 0;
}
.logo_wrap {
  text-align: center;
  padding: 50px 0px 20px 0px;
}
.logo_wrap img {
  width: 115px;
}
