.ant-select-dropdown-menu-item:first-child,
.ant-select-dropdown-menu-item:hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  /* background-color: var(--light-primary-color) !important; */
  color: var(--text-color-primary);
  background-color: transparent;
}
.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: transparent !important;
}
.ant-select-selection {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid var(--light-grey-color);
  border-radius: 3px;
  font-size: 16px;
}
.ant-form-item-control .ant-select-selection,
.ant-form-item .ant-select-selection {
  background-color: white;
}
.ant-select-selection--single {
  padding-left: 10px;
  padding-right: 20px;
}
.ant-select-selection:hover {
  border-color: var(--light-grey-color);
}
.ant-select-open .ant-select-selection {
  box-shadow: none;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: var(--text-color-primary);
  box-shadow: none;
}
.ant-dropdown-menu-item,
.ant-select-dropdown-menu-item {
  font-size: var(--base-font-size);
  line-height: 14px;
}
.ant-select-selection-selected-value,
.ant-select-auto-complete.ant-select .ant-input {
  font-size: var(--label-font-size);
}
.ant-select-sm .ant-select-selection__rendered,
.ant-select-selection__rendered {
  line-height: 35px;
}
.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected
  .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected:hover
  .ant-select-selected-icon {
  color: var(--text-color-primary);
}
.multi-select-input .ant-select-selection--multiple {
  height: auto !important;
  padding-bottom: 3px;
}
.ant-dropdown-menu {
  border-radius: 4px;
  box-shadow: 0 5px 25px 0 rgba(189, 194, 220, 0.4);
  border: solid 1px #d1d6f3;
  background-color: #ffffff;
}

/* react tags style */

.react-tags {
  position: relative;
  padding: 6px 0 5px 6px;
  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
  font-size: var(--base-font-size);
  box-shadow: none;
  min-height: 40px;
  border: 1px solid var(--light-grey-color);
  border-radius: 6px;
  background-color: var(--app-background-primary);
}
.react-tags.is-focused {
  border-color: var(--purple-color);
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 6px 3px 0;
  padding: 3px 6px 3px 24px;
  border: 1px solid var(--light-grey-color);
  border-radius: 4px;
  background: #f8faff;
  font-size: var(--label-font-size);
  line-height: inherit;
  color: var(--text-color-dark);
  font-weight: 600;
  position: relative;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: var(--bluey-grey);
  margin-left: 8px;
  font-size: 10px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: var(--light-grey-color);
  background: rgba(209, 214, 243, 0.2);
}
.react-tags__selected-tag::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--purple-color);
  position: absolute;
  left: 8px;
  top: 6px;
}
.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 2px 2px;
  margin-bottom: 4px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
  height: 23px;
  line-height: 23px;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  min-width: 235px;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  background-color: var(--app-background-primary);
}
.react-tags__search input::-webkit-input-placeholder {
  font-size: var(--label-font-size);
  font-weight: 600;
}
.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 99;
}
.react-tags__suggestions ul {
  border: 1px solid var(--light-grey-color);
  border-radius: 4px;
  box-shadow: var(--default-btn-shadow);
}
.react-tags__suggestions ul li {
  padding: 10px;
  background: #f8faff;
  color: var(--text-color-dark);
}
.react-tags__suggestions ul li:hover {
  background: rgba(209, 214, 243, 0.2);
}
@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

/* React select menu style */
.service-menu .ant-form-item-control {
  line-height: 21px;
}
.react-select-kc__control {
  height: 40px;
  min-height: 40px !important;
  font-size: var(--title-font-size);
  box-shadow: none;
  border: 1px solid var(--light-grey-color) !important;
  font-family: var(--base-font);
}
.react-select-kc__control.react-select-kc__control--is-focused,
.react-select-kc__control.react-select-kc__control--is-focused:hover {
  /* border-color: var(--light-primary-color); */
  box-shadow: none;
}

.ant-form-item-control:not(.has-error)
  .react-select-kc__control.react-select-kc__control--is-focused {
  border: 1px solid var(--purple-color) !important;
}
.ant-form-item-control.has-error .react-select-kc__control {
  border: 1px solid var(--text-color-red) !important;
}
.react-select-kc__value-container {
  height: 28px !important;
}
.react-select-kc__indicator-separator {
  height: 13px;
  margin-top: 7px !important;
  margin-bottom: 0;
  display: none;
}
.css-tlfecz-indicatorContainer {
  color: var(--text-color-dark) !important;
}
.react-select-kc__clear-indicator {
  padding: 3px !important;
}
.react-select-kc__indicator.react-select-kc__dropdown-indicator {
  padding: 3px;
}

.react-select-kc__option.react-select-kc__option--is-selected {
  background: var(--background-color-primary);
}
.react-select-kc__option.react-select-kc__option--is-selected:hover {
  color: var(--text-color-dark) !important;
}
.react-select-kc__option:hover,
.react-select-kc__option.react-select-kc__option--is-focused,
.react-select-kc__option:active {
  background: white !important;
  color: var(--text-color-primary) !important;
}
.react-select-kc__menu {
  border: 1px solid var(--light-grey-color);
  box-shadow: var(--de);
}
.ant-select-arrow {
  color: black;
  font-size: 10px;
  margin-top: -5px;
  right: 12px;
}
.ant-select-sm .ant-select-selection__rendered {
  margin-left: 0;
}
.ant-select-selection__rendered {
  margin-left: 0;
}
.ant-select,
.ant-select-dropdown,
.ant-dropdown-menu-item,
.ant-select-dropdown-menu-item {
  font-size: var(--label-font-size);
}
.ant-select-dropdown.dropdown-with-borders {
  border-radius: 4px;
  box-shadow: 0 5px 25px 0 rgba(189, 194, 220, 0.4);
  border: solid 1px #d1d6f3;
  background-color: #ffffff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
  top: 54px !important;
}
.lang-dd .ant-select-selection {
  background: transparent;
}
.ant-select-dropdown.dropdown-with-borders .ant-select-dropdown-menu-item {
  border-bottom: 1px solid #d1d6f3;
  padding: 13px 20px;
  font-size: 14px;
  color: var(--text-color-dark);
  position: relative;
  text-align: left;
}
.ant-select-dropdown.dropdown-with-borders
  .ant-select-dropdown-menu-item:last-child {
  border-bottom: none;
}
.dropdown-with-borders .ant-select-dropdown-menu {
  padding: 0;
}
.ant-form-item-children
  .ant-select-auto-complete.ant-select
  .ant-select-selection {
  background-color: var(--app-background-primary);
  border: 1px solid var(--light-grey-color);
}
.queue-action-dropdown {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  text-align: left;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 var(--cloudy-blue-28);
  border: solid 1px var(--light-grey-color);
}
.queue-action-dropdown .ant-menu-item {
  line-height: 14px;
  height: auto;
  padding: 5px 12px;
  color: var(--text-color-dark);
  margin: 0 !important;
}
.queue-action-dropdown .ant-menu {
  margin: 4px 0;
}
