.prompt-block {
  position: fixed;
  bottom: 20px;
  left: 30px;
  width: 320px;
  text-align: left;
  border: solid 1px #dfe3e9;
  border-radius: 4px;
  background-color: white;
  z-index: 999;
  overflow: hidden;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}
.prompt-wrapper {
  position: relative;
  padding: 24px 20px 12px 35px;
}
.prompt-border-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
}
.sms-info-bg {
  background: var(--background-color-primary);
}
.next-step-info-bg {
  background: var(--purple-color);
}
.prompt-image {
  position: absolute;
  top: 20px;
  left: 8px;
  width: 18px;
}
.prompt-heading {
  font-size: var(--base-font-size);
  color: black;
}
.prompt-message {
  font-size: var(--base-font-size);
  color: var(--dark-grey);
  margin-top: 5px;
}
.prompt-message ul {
  padding-left: 0;
}
.prompt-btn-wrap {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prompt-btn button {
  border: solid 1px #dfe3e9;
}
.prompt-checkbox {
  margin-top: 20px;
}

.prompt-image img {
  max-width: 100%;
}
