/* CSS for App Component */
.ui.menu .item:before {
  background: transparent;
}
.ui.menu .item.logo {
  padding-left: 10px;
}
.ui.menu.fixed {
  padding: 7px;
}
.user_avator {
  width: 25px;
}
.pt-6 {
  padding-top: 6px !important;
}
.ui.menu {
  border-radius: 0 !important;
  padding: 10px 0;
}
