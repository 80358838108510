.skeleton{
  position: absolute;
  z-index: 2;
}
/* Queue skeleton start */
.queue-skeleton{
  height: 90vh;
  width: 102%;
  margin-left: -1%;
  top: 45px;
  background: var(--app-background-primary);
   
}
.queue-skeleton-block{
  height: 50px;
  margin-top: 0px;
  width: 100%;
  background-image:
  linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%),
  radial-gradient(circle 10px, rgb(247, 247, 247) 99%, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#ffffff 42px, transparent 0);
   
  background-size:
  200px 100%,
  20px 20px,
  20% 20px,
  25% 20px,
  20% 20px,
  100% 42px;
  background-position:
  -150px 0px,
  10px 11px,
  10% 14px,
  50% 14px,
  90% 14px,
  0px 0px;
  z-index: 1;
  background-repeat:no-repeat ;
  animation: loadingQueue 2.5s infinite ease-in-out;
   
}
@keyframes loadingQueue {
  to {
    background-position:
      400% 0,        
      10px 11px,
      10% 14px,
      50% 14px,
      90% 14px,
      0px 0px;
  }
}

/* Queue skeleton end */

/* Queue skeleton start */
.appointments-skeleton{
  position: relative;
  height: 90vh;
  width: 102%;
  margin-left: -1%;
  top: 45px;
  background: var(--app-background-primary);
}
.appointments-skeleton-block{
  height: 260px;
  margin-top: 0px;
  width: 100%;
  background-image:
  linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%), 
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 2px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  /*next card background*/
  linear-gradient(#f7f7f7 15px, transparent 0),
  /*next card row*/
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),

  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),

  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),

  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),

  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  linear-gradient(#f7f7f7 15px, transparent 0),
  /*white boxes*/
  linear-gradient(#ffffff 260px, transparent 0),
  linear-gradient(#ffffff 260px, transparent 0);
    background-size:
    200px 100%, /* animating bg*/
    30% 20px,  
    100% 2px,
    8% 20px,
    25% 20px,
    10% 20px, 
    8% 20px,
    25% 20px,
    10% 20px,
    8% 20px,
    25% 20px,
    10% 20px, 
    8% 20px,
    25% 20px,
    10% 20px,
    8% 20px,
    25% 20px,
    10% 20px,
    /*next card background size*/
    20% 20px,
    /*next card row size*/
    18% 20px,
    5% 20px,
    5% 20px,

    18% 20px,
    5% 20px,
    5% 20px,

    18% 20px,
    5% 20px,
    5% 20px,

    18% 20px,
    5% 20px,
    5% 20px,

    18% 20px,
    5% 20px,
    5% 20px,
    
    57% 260px,
    40% 260px;
    background-position:
    -150px 0px,
    3% 14px,
    0 40px,
    
    2.3% 60px,
    20% 60px,
    50% 60px,
    2.3% 100px,
    20% 100px,
    50% 100px,
    2.3% 140px,
    20% 140px,
    50% 140px,
    2.3% 180px,
    20% 180px,
    50% 180px,
    2.3% 220px,
    20% 220px,
    50% 220px,
    /*next card background position*/
    78% 14px,
    /*next card row position*/
    76% 60px,
    90% 60px,
    97% 60px,

    76% 100px,
    90% 100px,
    97% 100px,

    76% 140px,
    90% 140px,
    97% 140px,

    76% 180px,
    90% 180px,
    97% 180px,

    76% 220px,
    90% 220px,
    97% 220px,



    0px 0px,
    100% 0px;
  z-index: 1;
  background-repeat:no-repeat ;
  animation: loadingAppointments 2s infinite ease-in-out;
   
}

@keyframes loadingAppointments {
  to {
    background-position:
    300% 0px,
    3% 14px,
    0 40px,
    
    2.3% 60px,
    20% 60px,
    50% 60px,
    2.3% 100px,
    20% 100px,
    50% 100px,
    2.3% 140px,
    20% 140px,
    50% 140px,
    2.3% 180px,
    20% 180px,
    50% 180px,
    2.3% 220px,
    20% 220px,
    50% 220px,
    /*next card background position*/
    78% 14px,
    /*next card row position*/
    76% 60px,
    90% 60px,
    97% 60px,

    76% 100px,
    90% 100px,
    97% 100px,

    76% 140px,
    90% 140px,
    97% 140px,

    76% 180px,
    90% 180px,
    97% 180px,

    76% 220px,
    90% 220px,
    97% 220px,



    0px 0px,
    100% 0px;
    
  }
}
/* Filter skeleton start */

.filter-skeleton{
  height: 50px;
  position: absolute;
  width: 102%;
  margin-left: -1%;
  top: -5px;
  z-index: 2;
  background-color: var(--app-background-primary);
  background-image:
  linear-gradient(90deg, rgba(247,247,247, 0) 0, rgba(247,247,247, 0.8) 50%, rgba(247,247,247, 0) 100%),
  linear-gradient(#ffffff 30px, transparent 0),
  linear-gradient(#ffffff 30px, transparent 0);

   
  background-size:
  200px 100%,
  30% 20px,
  20% 20px;
  background-position:
  -150px 0px,
  0 14px,
  100% 14px;
  background-repeat:no-repeat ;
  animation: loadingFilters 3s infinite ease-in-out;
   
}
@keyframes loadingFilters {
  to {
    background-position:
      400% 0,        
      0 14px,
      100% 14px;
  }
}

/* Filter skeleton end */



