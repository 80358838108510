.select_account_wrap ul {
  list-style: none;
  max-width: 820px;
  margin: 0 auto;
  height: calc(100vh - 400px);
  min-height: 400px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select_account_wrap ul li {
  max-width: 400px;
  min-height: 375px;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  cursor: pointer;
  box-shadow: 0 -5px 35px 0 rgba(189, 194, 220, 0.3);
  border: solid 1px var(--light-grey-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.select_account_wrap ul li:nth-child(1)::after {
  content: "";
  width: 2px;
  position: absolute;
  right: 0;
  top: 38%;
  height: 130px;
  background: #eee;
  border-radius: 50%;
}
.sa-image-wrapper {
  width: 170px;
  height: 170px;
  background-size: contain;
  /* transition: all 0.5s ease-in-out; */
}
.sa-image-wrapper.growth {
  background-image: url("../images/image-Growth.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
/* .select_account_wrap li:hover {
  opacity: 1;
} */
/* .select_account_wrap li:hover .sa-image-wrapper {
  transform: scale(1.05);
} */
.sa-image-wrapper.clinic {
  background-image: url("../images/image-CMS.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.sa-text-wrapper {
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}
.sa-text-wrapper h3 {
  font-size: 24px;
  color: black;
  margin-bottom: 5px;
}
.sa-text-wrapper p {
  color: black;
}
.pt-55 {
  padding-top: 55px;
}
.ls_1 {
  letter-spacing: 1px;
}
.select_account_button {
  width: 100%;
  margin-top: 20px;
}
.mt-100{
  margin-top: 100px;
}
.no-net-icon-block a{
  display: block;
  color: #dd5c5c;
}
.no-net-icon-block img{
  width: 20px;
}
.network-alert {
  margin-top: 5px;
  color: white;
  font-weight: bold;
  border-radius: 0;
  width: 390px;
  position: fixed;
  top: 0px;
  z-index: 1010;
  left: 50%;
  margin-left: -195px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 9999;
}
.network-text {
  width: 60%;
  display: inline-block;
  background: var(--text-color-green);
  padding: 10px;
  border: 1px solid var(--text-color-green);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.net-icon-block {
  display: inline-block;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
  background: #ffffff;
  width: 40%;
  text-align: center;
}
.net-icon-block a{
  color: var(--text-color-green);
  text-decoration: none;
}
.network-text img{
  width: 18px;
  margin-right: 5px;
}
@media(max-width: 420px){
  .network-alert{
    width: 300px;
    margin-left: -150px;
  }
  .network-text img{
    width: 15px;
  }
  .network-text,.net-icon-block{
    font-size: 12px;
    padding: 10px 5px;
  }
  .net-icon-block{
    width: 34%;
  }
  .network-text{
    width: 66%;
  }
}