.ant-list-item-meta-description{
  font-size: 12px;
}
.ant-list-item{
  padding: 5px 0;
}
.ant-list-item-meta-title{
  margin-bottom: 0;
  font-size: 12px;
  line-height: 16px;
}
.ant-list-item-meta-description{
  width: 100%;
  word-break: break-all;
}