@import url("./theme/variables.css");

.login-form-wrapper {
  padding: 30px 5px;
  box-shadow: 0 -5px 35px 0 rgba(189, 194, 220, 0.3);
  border: solid 1px var(--light-grey-color);
  background-color: #ffffff;
  max-width: 570px;
  padding: 60px;
  padding-top: 45px;
}
@media (min-width: 768px) {
  .login-form-wrapper {
    width: 570px;
  }
}
.login-form-wrapper .ant-form-explain {
  margin-top: 2px;
}
.login-form-wrapper .ant-input-affix-wrapper {
  height: 36px;
}
.auth-logo {
  text-align: center;
  padding: 0px 30px 30px;
}
.login-form-button.ant-btn {
  width: 100%;
  height: 42px;
  font-size: 14px;
}
.centered-image {
  display: block;
  margin: 0 auto 30px auto;
}
.hide {
  display: none;
}
.app-modal .ant-modal-content {
  background-color: #f8f9fd;
}
#navcontainer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.auth-form {
  font-size: 1rem;
  line-height: 1.25;
  min-height: calc(100vh - 240px);
}
.auth-form-row {
  /* position: relative; */
  /* top: 2.5rem; */
  margin: 0 2.5rem;
  margin-top: 55px;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1 0;
  flex: 1 0;
  min-width: 0;
  /* height: calc(100vh - 2 * 2.5rem); */
  border-radius: 0.375rem;
  font-weight: 400;
  overflow: visible;
}
.login-title {
  color: var(--text-color-dark);
  margin-bottom: 0.75rem;
  font-weight: 400;
}
.login-sub-title {
  color: #a4abb3;
  font-weight: 400;
  /* margin-bottom: 1.5rem; */
  font-size: var(--base-font-size);
}
.login-form-wrapper .ant-input {
  height: 42px;
}
.login-form-forgot {
  float: right;
  font-weight: 400;
}
.login-form-wrapper .ant-form-item {
  margin-bottom: 12px;
}
.password-list {
  padding-left: 15px;
}
.resend-wrap {
  font-size: var(--base-font-size);
  padding-bottom: 5px;
  color: var(--text-color-primary);
}
.account_heading {
  color: var(--text-color-primary);
  font-size: 18px;
  margin-bottom: 6px;
  font-weight: bold;
}
.account_desc {
  font-size: 14px;
  color: grey;
}
.select-number-wrap {
  padding: 15px 50px 15px 20px;
  border-radius: 10px;
  background: #ffffff;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.2;
  transition: all 0.2s 0s;
  box-shadow: var(--btn-box-shadow);
  cursor: pointer;
  text-transform: capitalize;
  /* background-image: url("https://kulcare-assets.s3-ap-southeast-1.amazonaws.com/images/right_arrow_small.png"); */
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: right 20px center;
  border: 1px solid #eeeeee;
}
.select-number-wrap:hover {
  box-shadow: 0px 1px 5px 1px rgba(50, 50, 50, 0.2);
}

.magic-link-main-wrapper {
  width: 100%;
  margin-top: 0;
  padding: 0;
  min-height: 100vh;
}
.download-success-wrapper{
  max-width: 280px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0 2px 5px 0 rgb(189 194 220 / 40%);
  background-color: #ffffff;
  padding: 0 40px;
}

@media (max-width: 500px) {
  .magic-link-main-wrapper {
    /* background-image: linear-gradient(
      151deg,
      var(--bright-sky-blue),
      var(--text-color-primary)
    ); */
    background: var(--text-color-primary) !important;
  }
  .color-white {
    color: white !important;
  }
}
