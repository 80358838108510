@media (max-width: 890px) {
  .select_account_wrap ul {
    max-width: 95%;
    flex-direction: column;
    height: auto;
  }
  .select_account_wrap ul li {
    margin-top: 20px;
    width: 100%;
    height: 50%;
  }
}
@media (max-width: 768px) {
  .sa-text-wrapper p {
    font-size: 13px;
  }

  .sa-text-wrapper h3 {
    font-size: 20px;
  }
  .ant-select-dropdown.dropdown-with-borders .ant-select-dropdown-menu-item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .login-form-wrapper {
    width: 300px;
  }
}
@media (max-width: 500px) {
  .select_account_wrap ul {
    margin-left: 20px;
    margin-right: 20px;
  }
  .wizard-wrap {
    width: 300px;
  }
  .auth-form-row {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 100px;
  }
  .login-form-wrapper {
    padding: 20px;
  }
  .footer-image {
    display: none;
  }
}
