.ant-time-picker-panel-select li:hover{
  background: var(--light-primary-hover-color);
}
.ant-time-picker-input:hover{
  border-color: var(--background-color-primary);
}
.ant-time-picker-small .ant-time-picker-input{
  height: 32px;
}
.ant-time-picker-panel-select li:focus{
  color: var(--text-color-primary);
}
.ant-time-picker-panel-inner{
  border: 1px solid rgba(0,0,0,0.1);
}