.ant-input {
  font-size: var(--label-font-size);
  -webkit-box-shadow: var(--btn-box-shadow);
  box-shadow: var(--btn-box-shadow);
  height: 35px;
  border: 1px solid transparent;
  font-family: var(--base-font);
  line-height: 35px;
  color: var(--text-color-dark);
}
.ant-input:not(.ant-calendar-picker-input) {
  background-color: var(--app-background-primary);
}

.ant-input-affix-wrapper:hover {
  border-color: transparent !important;
}
.ant-input:not(.ant-input-disabled) {
  /* border-color: transparent; */
}
.ant-input:not(.ant-select-search__field):hover {
  /* border-color: var(--light-grey-color) !important; */
  box-shadow: none;
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  font-size: var(--base-font-size);
  color: var(--text-color-red);
  font-weight: 400;
  margin-top: 1px;
}

.ant-checkbox-wrapper + span,
.ant-checkbox + span {
  font-size: var(--base-font-size);
  font-family: var(--base-font);
  font-weight: 400;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--background-color-primary);
  border-color: var(--background-color-primary);
}
/* .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--background-color-primary);
} */
.ant-radio-checked .ant-radio-inner {
  border-color: var(--background-color-primary);
}
.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-focused .ant-radio-inner {
  border-color: var(--background-color-primary);
}
.ant-input-group-addon:first-child {
  font-size: var(--base-font-size);
  -webkit-box-shadow: var(--btn-box-shadow);
  box-shadow: var(--btn-box-shadow);
  height: 28px;
  border: 1px solid transparent;
  font-family: var(--base-font);
}
.ant-input-group {
  box-shadow: var(--btn-box-shadow);
  border-radius: 4px;
}
.ant-input-group > .ant-input {
  box-shadow: none;
}
.ant-input-group-addon {
  border: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: var(--text-color-primary);
}
.ant-form-item-label {
  line-height: 19px;
  font-size: var(--label-font-size);
  margin-bottom: 5px;
}
.ant-form label,
.ant-form-item-label > label {
  font-size: var(--label-font-size);
  font-weight: bold;
  color: var(--text-color-dark);
}
.ant-form-item {
  margin-bottom: 15px;
}
.ant-select-selection--multiple {
  min-height: 40px;
  padding-bottom: 0;
  /* height: 28px; */
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 20px;
  line-height: 19px;
  margin-bottom: 2px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  font-size: var(--base-font-size);
}
textarea.ant-input {
  min-height: 40px;
  line-height: 1.5;
  background: var(--app-background-primary);
  line-height: 31px;
}
.has-error .ant-select-selection {
  border-color: transparent;
}
.ant-select-auto-complete.ant-select
  .ant-input:not(.ant-select-search__field):focus {
  border-color: var(--light-primary-color) !important;
  box-shadow: none;
}
.ant-select-auto-complete.ant-select .ant-input[disabled] {
  z-index: 0;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input,
.ant-calendar-picker-container {
  font-family: var(--base-font);
  font-size: var(--base-font-size);
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  top: -2px;
}
.ant-calendar-header {
  height: 38px;
  line-height: 38px;
}
.ant-input-number-sm {
  height: 40px;
  line-height: 40px;
}
.ant-input-number-sm input {
  font-family: var(--base-font);
  font-size: var(--label-font-size);
  height: 100%;
  padding: 9px 7px;
  color: var(--text-color-dark);
  background-color: var(--app-background-primary);
}
.DayPicker-Day,
.DayPicker-Weekday,
.ant-input-group .ant-input {
  font-family: var(--base-font);
  font-size: var(--base-font-size);
}
.ant-calendar-range-picker-input {
  height: 100%;
  padding: 0;
}
.ant-form-item-control {
  line-height: 29.9999px;
}
.dob-input-with-btn .dob-input {
  width: 75%;
  margin-right: 4%;
}
.dob-input-with-btn .dob-input[disabled] {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
@media (max-width: 992px) {
  .ant-calendar-picker-clear,
  .ant-calendar-picker-icon {
    right: 6px;
  }
}
.ant-input-password-icon {
  font-size: 16px;
  padding-top: 4px;
}
.ant-input-sm.inline-input-small {
  box-shadow: none;
  border-bottom: 1px solid #cacaca;
  border-radius: 0;
  background: transparent;
  height: 25px;
  width: 75px;
  text-align: right;
  padding-right: 1px;
}
.ant-input-sm.inline-input-small:focus,
.ant-input-sm.inline-input-small:hover {
  border-color: transparent !important;
  border-bottom-color: var(--light-primary-color) !important;
}

.ant-input.ant-select-search__field {
  padding: 0;
}
.ant-calendar-picker-input.ant-input {
  line-height: 35px !important;
  height: 35px !important;
  box-shadow: none;
  font-size: 14px;
  border-color: white;
  font-weight: bold;
  padding: 2px 0px;
  text-align: center;
}
.ant-calendar-picker-input.ant-input:hover {
  border-color: white !important;
}
.ant-input:not(.ant-select-search__field):not(textarea) {
  height: 35px;
}
.ant-input:not(.ant-select-search__field) {
  box-shadow: none;
  border: 1px solid var(--light-grey-color);
  border-radius: var(--controls-border-radius) !important;
}

.ant-input:focus {
  border: 1px solid var(--text-color-primary);
  box-shadow: none;
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  font-size: var(--small-font);
}
.ant-input-group {
  box-shadow: none;
}
.ant-input-group-addon:first-child {
  font-size: var(--title-font-size);
  box-shadow: none;
  background: white;
  border: 1px solid var(--light-grey-color);
  border-right: none;
}
.ant-input-group > .ant-input {
  border-left: none;
}
.ant-input-group > .ant-input::-webkit-input-placeholder {
  font-size: var(--title-font-size);
}
.ant-input-group > .ant-input ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: var(--title-font-size);
}
.ant-input-group > .ant-input :-ms-input-placeholder {
  /* IE 10+ */
  font-size: var(--title-font-size);
}
.ant-input-group > .ant-input :-moz-placeholder {
  /* Firefox 18- */
  font-size: var(--title-font-size);
}
.ant-input-group > .ant-input:focus {
  border-left: 1px solid var(--purple-color);
}
.has-error .ant-input:not(.ant-select-search__field),
.has-error .ant-input:not(.ant-select-search__field):hover {
  border-color: var(--text-color-red) !important;
}
.has-error .ant-input.ant-select-search__field,
.has-error .ant-input.ant-select-search__field:hover {
  border-color: transparent;
}

.has-error .ant-input:focus {
  box-shadow: none;
}
.ant-input::-webkit-input-placeholder {
  color: var(--bluey-grey);
}
.ant-form-item-children .ant-input {
  background-color: white;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: var(--light-grey-color);
}
.ant-input-number {
  color: var(--text-color-dark);
}
.ant-input-number:hover {
  border-color: var(--light-grey-color);
}
.ant-input-number-focused {
  border-color: var(--purple-color);
}
.form-label-block {
  display: block;
  padding-bottom: 8px;
  font-size: var(--label-font-size);
  font-weight: 700;
  color: var(--text-color-dark);
}
.block-with-app-bg .ant-form-item-children .ant-input {
  background-color: white;
}
.block-with-app-bg .react-tags,
.block-with-app-bg .react-tags__search input {
  background: white;
}
.block-with-app-bg
  .ant-form-item-children
  .ant-select-auto-complete.ant-select
  .ant-select-selection {
  background-color: white;
}
.block-with-app-bg .geosuggest__input {
  background-color: white;
}
.ant-form-explain,
.ant-form-extra {
  min-height: auto;
  line-height: 1.2;
}
.has-error .ant-input-affix-wrapper .ant-input:focus {
  box-shadow: none;
}
.ant-form-explain {
  margin-top: 2px;
  color: var(--text-color-dark);
}
