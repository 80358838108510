.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  text-align: center;
  color: var(--text-color-dark);
  padding: 7px 12px !important;
  font-size: var(--base-font-size);
}
.ant-table-thead > tr > th {
  background: var(--app-background-primary) !important;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.ant-table-tbody > tr:hover > td {
  background: var(--app-background-primary) !important;
}
.ant-table-pagination.ant-pagination {
  margin: 12px 10px;
}
.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: var(--background-color-primary);
}
.ant-pagination-item-active a,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: var(--text-color-primary);
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: var(--background-color-primary);
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: transparent;
  color: var(--text-color-primary);
}
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: var(--text-color-primary);
}
.ant-spin-dot i {
  background-color: var(--background-color-primary);
}
.ant-table-tbody {
  background: #fff;
}
.ant-table-tbody > tr > td:first-child{
  padding-left: 20px !important;
}
.ant-table-thead > tr:first-child > th:first-child{
  padding-left: 20px !important;
}
.ant-table-thead{
  border-top: 1px solid #e8e8e8;
}
.table-without-head .ant-table-thead{
  display: none;
}
.table-header{
  border-bottom: 1px solid #e8e8e8;
  background: var(--app-background-primary);
 
  text-transform: uppercase;
  color: var(--text-color-dark);
  font-family: "Exo";
  font-size: var(--base-font-size);
  padding: 7px 12px 7px 20px !important;
}
.ant-table-bordered .ant-table-body > table{
  border:none;
}
.ant-table-bordered .ant-table-tbody > tr > td:last-child{
  border-right: none;
}
.ant-table-placeholder{
  font-size: var(--base-font-size);
}
button, select{
  font-family: var(--base-font);
  font-size: var(--base-font-size);
}
.without-td-padding .ant-table-tbody > tr > td{
  padding:0 !important;
  

}