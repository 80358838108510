.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  line-height: 30px;
  height: 30px;
  font-size: var(--base-font-size);
  /* border: none; */
  border-color: rgba(170, 183, 196, 0.47);
}
/* .ant-tabs-bar {
  border-bottom: none;
} */
.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--text-color-primary) !important;
}
.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-nav-container {
  height: 32px;
}
.line-tabs.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active{
  background: transparent !important;
}
.line-tabs.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab{
  background: transparent !important;
  border:none;

}
.line-tabs .ant-tabs-nav .ant-tabs-tab-active,
 .line-tabs .ant-tabs-nav .ant-tabs-tab:hover{
  border-bottom: 2px solid var(--text-color-primary) !important;
  padding-bottom: 0!important;
}
.line-tabs .ant-tabs-bar{
  border-bottom: none;
}
.main-tabs >.ant-tabs-bar{
  border-bottom: 1px solid rgba(170, 183, 196, 0.47);
}
.main-tabs.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active{
  background: var(--app-background-primary) !important;
}
.ant-tabs{
  font-family: var(--base-font);
}
.ant-tabs-tab a{
  color: var(--text-color-dark) !important;
  display: inline-block;
  padding: 0 10px;
}
.ant-tabs-tab-active a{
  color: var(--text-color-primary) !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
  padding: 0 6px;
}