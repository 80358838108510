.signup-form-top-heading {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 15px;
  color: #353949;
}
.auth-form-input-block-container .ant-form-item-control {
  margin-top: -12px;
}
@media (max-width: 991px) {
  .signup-form-top-heading {
    font-size: 18px;
  }
}
@media (max-width: 500px) {
  .signup-form-top-heading {
    font-size: 16px;
  }
  .label-prop {
    font-size: 13px !important;
  }
}

.signup-form-main-wrapper {
  width: 367px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 15px 35px 0 var(--sail-color-shadow-ambient),
    0 5px 15px 0 var(--sail-color-shadow-key);
  padding: 34px 24px 34px 24px;
  border-radius: var(--controls-border-radius);
}

.img-block {
  background-image: url("../../../images/true_check.svg");
  width: 22px;
  height: 22px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  display: flex;
  margin-right: 13px;
  margin-top: 3px;
}
.signup-text-container {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 27px;
}
.signup-text-heading {
  font-size: 16px;
  line-height: 20px;
}
.signup-text-content {
  font-size: 14px;
  margin-top: 8px;
  width: 381px;
  line-height: 18px;
  color: #000000;
}

.signup-left-section {
  margin-right: 124px;
}
.auth-left-screen {
  margin-right: 50px;
}

.auth-left-screen {
  width: 400px;
  height: 400px;
}
.auth-left-screen.patient-account-screen::before {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35vw;
  height: 35vw;
  margin-left: 50px;
  margin-top: 50px;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  content: "";
  background-image: url("../../../images/patient-left-screen.png");
}
.auth-left-screen.hospital-account-screen::before {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 40vw;
  height: 27vw;
  margin-left: 50px;
  margin-top: 50px;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  content: "";
  background-image: url("../../../images/hospital-left-screen1.png");
}
@media (max-width: 991px) {
  .signup-left-section {
    margin-right: 0;
    width: 43%;
    min-height: auto;
  }
  .signup-text-content {
    width: auto;
  }
  .auth-left-screen {
    width: 300px;
    height: 300px;
  }
}
@media (max-width: 768px) {
  .signup-wrapper {
    width: 100%;
    height: 90%;
    margin-top: 80px;
    margin: auto;
    min-height: auto;
  }

  .img-block {
    width: 18px;
    height: 18px;
  }
  .signup-left-section {
    display: none;
    width: 0;
  }
  .auth-left-screen {
    display: none;
  }
}

@media (max-width: 991px) {
  .signup-text-heading {
    font-size: 14px;
  }
  .signup-text-content {
    font-size: 12px;
  }
}
.ant-alert-message,
.ant-notification {
  left: 10px !important;
  top: 10px !important;
  margin-left: 0 !important;
}
/* .auth-form-section {
  padding-top: 80px;
} */

.main-wrapper-container {
  margin-left: 7%;
}
@media (max-width: 991px) {
  .main-wrapper-container {
    margin-left: 0;
    padding: 0 30px;
  }
}
.signup-intent-container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .signup-intent-container {
    flex-direction: column;
  }
}
