.ant-layout-header {
  background: #ffffff;
  /* background: white; */
  /* box-shadow: 0px 5px 25px rgba(189, 194, 220, 0.4); */
  height: 70px;
  line-height: 60px;
  z-index: 3 !important;
  position: fixed;
  width: 100%;
  padding: 5px 0;
  /* box-shadow: 0 2px 25px 0 rgba(189, 194, 220, 0.4); */
  /* border: solid 1px #d1d6f3; */
  border-top: none;
}
.top-logo img {
  width: 122px;
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}
.top-user-avatar .ant-avatar-sm {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  border: 1px solid #aab7c4;
}
.switch_account_link {
  margin-left: 30px;
  font-weight: 700;
  color: var(--text-color-dark);
}
.switch_account_link:hover,
.switch_account_link:focus {
  color: rgba(0, 0, 0, 0.65);
}
.switch_account_link i {
  font-size: 12px;
  padding-left: 5px;
}
.switch_account_link span {
  color: var(--text-color-primary);
}
.top-nav-profile-icon-down.anticon {
  color: black;
  font-weight: 600;
  margin-left: 10px;
}
.top-nav-dropdown {
  top: 68px !important;
  left: auto !important;
  right: 4px;
  width: 220px !important;
  max-width: 220px !important;
  min-width: auto !important;
}
.top-nav-dropdown .ant-dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
}
.top-nav-dropdown .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-divider) {
  border-bottom: 1px solid #d1d6f3;
  padding: 13px 20px;
  font-size: 14px;
  color: var(--text-color-dark);
  position: relative;
}
.top-nav-dropdown
  .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-divider):after {
  content: "";
  width: 15px;
  height: 15px;
  background: url("../../images/arrow-right-blue.svg") no-repeat center;
  position: absolute;
  right: 15px;
}
.top-nav-dropdown .ant-dropdown-menu-item:first-child:after {
  background: none;
}
.top-nav-dropdown .ant-dropdown-menu-item:last-child {
  border-bottom: none;
}
.top-nav-dropdown .ant-dropdown-menu-item:hover {
  background-color: transparent !important;
}
.menu-user-info {
  max-width: 140px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.menu-user-info p {
  margin-bottom: 0;
  white-space: normal;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}
.menu-user-info p.user-name {
  font-size: 16px;
  font-weight: 700;
  color: var(--text-color-dark);
  margin-bottom: 8px;
  line-height: 20px;
}
.menu-user-info p.user-role {
  overflow: visible;
  color: var(--text-color-primary);
}
.clinic-name-top {
  color: var(--text-color-dark);
  font-weight: bold;
  line-height: 1;
  font-size: 14px;
}
p.top-phone-no {
  color: var(--bluey-grey);
  font-size: 12px;
  margin-bottom: 6px;
}
.clinic-details-top {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  max-width: 260px;
}
.lang-dd .ant-select-selection {
  border: none;
}
.lang-dd .ant-select-selection-selected-value {
  font-weight: 700;
  font-size: 14px;
  color: var(--text-color-dark);
}
.lang-dd .ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 0;
  line-height: 22px;
}
.lang-dd .ant-select-focused .ant-select-selection,
.lang-dd .ant-select-selection:focus,
.lang-dd .ant-select-selection:active {
  box-shadow: none;
}
.ml-5{
  margin-left: 5px;
}
@media (max-width: 768px) {
  .top-logo img {
    width: 75px;
  }
}
